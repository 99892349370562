import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  Hero,
  Footer,
  Pricing,
  //References,
  //Reference,
  FeatureTestimonial,
  Signup,
  //GetStarted,
  Video,
  FeaturedIn,
  VideoSection,
  BlogSection,
  Logot,
  Logo
} from '../components';
import {WebLayout} from '../layouts'
import {graphql} from "gatsby"; 

const IndexPage = ({t, i18n, data, categories}) => {
  return (
    <WebLayout
      title="Duuers - ohjelmisto, jolla luot tarjouksesi vain 60 sekunnissa"
      meta={[
        {name: 'description', content: 'Duuers on suomalainen startup, jonka tarjoustyökaluohjelmistolla luot tarjouksesi ennennäkemättömän nopeasti ja helposti. Rekisteröidy ilmaiseksi nyt!'},
        {name: 'keywords', content: ''},
      ]}
      link={[{
          rel: "alternate", href: "https://www.duuers.com/fi/", hreflang: "fi"
      },{
          rel: 'alternate', href: 'https://www.duuers.com/', hreflang: 'en'
      },{
          rel: 'alternate', href: 'https://www.duuers.com/', hreflang: 'x-default'
      }]}
    >
      <Hero>
        <h1 className={"font_second semibold font40"}>{t('index.header')}</h1>
        <p className="font20 padding_top20 black opacity9 text">
          {t('index.text')}
        </p>
        <Video src="https://www.youtube.com/embed/r3ZWo4RpNMM?autoplay=1" blue/>
        <Signup/>
        <p className="font14 padding_top15 black opacity9 text">{t('component.hinnasto.fi2')}</p>
      </Hero>
{/*      
// <section className="content_4 bg_white padding_bottom25">
      <div className="container nopadding max_width970 text-center">
        <h2 className="top50 font_second light font42">Duuers.com on nyt osa Louhi Networks Oy:tä</h2>
        <div className="max_width770 margin_auto top45 font22 light text">
          Duuers.com palvelu on siirtynyt Louhi Networks Oy:n omistukseen - palvelu jatkuu ja kehittyy
          <br></br>
          <br></br>
          
        </div>
      </div>
    </section>
*/}
{ 
/* 
// Commented the Reference slide details
      <References>
  			<Reference  name="Iiro Hänninen" title="Myyntipäällikkö ja kumppanisi korteissa" company="Korttilinna" image="/images/references/iiro_500x500.png">
          Tarjousten laatu on parantunut huomattavasti ja asiakkaat ovat antaneet pointsit palvelusta! Takaisin vanhaan ei ole paluuta!
  			</Reference>
        <Reference  name="Ari Järvinen" title="Energia-alan yrittäjä" company="Lamit.fi" image="/images/references/ari_500x500.png">
          Duuers osoittautui ylivoimaisesti helppokäyttöisimmäksi tarjoustyökaluksi, koska se keskittyy olennaiseen.
  			</Reference>
  			<Reference  name="Mikko Heiskanen" title="Myynnintuottaja ja yrittäjä" company="Myynnintuottajat Group" image="/images/references/mikko_500x500.png">
          Olen suositellut Duuersia koko verkostolleni! Duuersin tarjoustyökalun avulla olemme tehostaneet tarjouksen lähettämisen prosessia huomattavasti.
  			</Reference>
  			<Reference name="Reeta Laamo" title="Luova yrittäjä" company="Red & Blue" image="/images/references/reeta_laamo_500x500.png">
          Duuersin avulla pystyn muokkaamaan lähettämääni tarjousta jo ennenkuin asiakas on avannut tarjouksen. Aloitan päiväni aina katsomalla viimeisimmät tapahtumat Duuersissa.
        </Reference>
        <Reference name="Marko Eskola" title="Myynti-insinööri ja yrittäjä" company="Arctic Team" image="/images/references/marko_500x500.png">
          Yksi Duuersin parhaita ominaisuuksia on, että pystyn seuraamaan miten asiakasprosessi etenee ja pystyn viemään tarjouksen maaliin yhdessä asiakkaan kanssa.
        </Reference>
  			<Reference name="Jani Saarinen" title="Henkilövuokraus yrittäjä" company="JAX" image="/images/references/jax_500x500.png">
          Duuersin paras ominaisuus on, että tarjouspohja on aina valmiina ja helposti muokattavissa - missä ja milloin vain.
        </Reference>
        <Reference  name="Tuukka Ranta-Pere" title="Moderni tilitoimistoyrittäjä" company="Dreamfaktori" image="/images/references/tuukka_500x500.png">
          Tarjousten laatiminen on ollut omassa työssä nihkeintä. Eipä ole enää! 
  			</Reference>
        <Reference  name="Henri Nuutinen" title="Autokouluyrittäjä" company="Ajomesta Nuutinen" image="/images/references/henri_500x500.png">
          Duuersin luoman huikean tarjouspohjan avulla pääsimme nopeasti alkuun. Nyt tarjoustyöskentely ja kommunikointi asiakkaan kanssa sujuu paremmin kuin koskaan aikaisemmin! 
  			</Reference>
        <Reference name="Salme Kortelainen" title="HR-palvelu yrittäjä" company="Calmen HR" image="/images/references/salme_500x500.png">
          Duuersin avulla tarjousten laatiminen on vaivatonta. Lopputuloksena on ulkomuodoltaan selkeä ja helppolukuinen tarjous.
        </Reference>
  			<Reference  name="Heikki Holmström" title="Remonttialan yrittäjä" company="Munkkiniemen Remonttiapu" image="/images/references/heikki_500x500.png">
          Onneksi lähdin mukaan, koska ohjelma oli loppujen lopuksi tosi helppo ottaa käyttöön. Nyt teen tarjoukset 10 minuutissa, kun aikaisemmin niihin meni kaksi tuntia!
  			</Reference>
        <Reference  name="Jani Teräväinen" title="Rakennusalan yrittäjä" company="Työmaapalvelut Express" image="/images/references/jani_500x500.png">
          Duuers on tärkein työkaluni, jonka avulla tarjoustyöskentelyni on tehostunut huomattavasti ja koko tiimini on aina ajantasalla tehdyistä ja hyväksytyistä tarjouksista. 
  			</Reference>
      </References>
      
      */
}

      <section className={`content_34 padding_top50 padding_bottom0 bg_light_gray`}>
        <div className="container padding_bottom0  text-center">
         {/* <h2 className="font42 light font_second dark_gray">Asiakkaitamme</h2> */}
          <h2 className="font42 light font_second dark_gray">{t('component.ourcustomers.title')}</h2>
          <Logot>
            <Logo 
            image1="/images/asiakkaat_logot/epu_coat_logo.png"
            image2="/images/asiakkaat_logot/tyomaapavelut_logo.jpg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/poukama_logo.jpg"
            image2="/images/asiakkaat_logot/Prosessipuhdistus-Logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/blommendahl_logo.jpg"
            image2="/images/asiakkaat_logot/digispace_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Evigo_logo.png"
            image2="/images/asiakkaat_logot/fslog.webp"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Harjulan_palvelut_logo.png"
            image2="/images/asiakkaat_logot/hawkhill_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/helpot_kotisivut_brand_logo.svg"
            image2="/images/asiakkaat_logot/KopterCam-White.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/mainosrakentajat_logo.png"
            image2="/images/asiakkaat_logot/mainostoimisto_luma_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Nostick_logo.png"
            image2="/images/asiakkaat_logot/photocom_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/Raaka_Media_logo.png"
            image2="/images/asiakkaat_logot/sisustuskorjaamo_logo.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/SisustusMinnaK_logo.png"
            image2="/images/asiakkaat_logot/solves_logo.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/stooribysara_logo.png"
            image2="/images/asiakkaat_logot/succee-logo.svg"
            />
            <Logo 
            image1="/images/asiakkaat_logot/tavux_logo.png"
            image2="/images/asiakkaat_logot/thebimcrowd_logo.png"
            />
            <Logo 
            image1="/images/asiakkaat_logot/villageworks_logo.jpg"
            image2="/images/asiakkaat_logot/3Economix_logo.png"
            />
          </Logot>
      
        </div> 
      </section>
      
      <div id="features">
        <FeatureTestimonial
            sectionid="faster"
            title={t('component.featureTestimonial.faster.title')}
            description={t('component.featureTestimonial.faster.description')}
            srcSet="/images/features/better-sales@2x_.png 1x, /images/features/better-sales@2x_.png 2x"
            src="/images/features/proposal-creation@2x.png"
            //linkkiteksti="Lue mitä mieltä asiakkamme ovat Duuersista. →"
            //linkkiurl="https://www.duuers.com/fi/referenssitarinat"
            linkkiteksti={t('component.featureTestimonial.faster.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.faster.linkkiurl')}
          />
          <FeatureTestimonial
            sectionid="professional"
            title={t('component.featureTestimonial.professional.title')}
            description={t('component.featureTestimonial.professional.description')}
            srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
            src="/images/features/proposal-creation@2x.png"
            //linkkiteksti="Katso miltä digitaalinen tarjouksesi voi näyttää asiakaalle. →"
            //linkkiurl="https://app.duuers.com/preview-recipient-view/fi"
            linkkiteksti={t('component.featureTestimonial.professional.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.professional.linkkiurl')}
            invert={true}
            target="_blank"
          />
          <FeatureTestimonial
            sectionid="data"
            title={t('component.featureTestimonial.data.title')}
            description={t('component.featureTestimonial.data.description')}
            //srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
            //src="/images/features/proposal-creation@2x.png"
            srcSet="/images/features/teamwork-fi.png 1x, /images/features/teamwork-fi@2x.png 2x, /images/features/teamwork-fi@3x.png 3x" 
            src="/images/features/teamwork-fi.png"
            //linkkiteksti="Duuersilla on helppo seurata tarjouksen tilaa. →"
            //linkkiurl="https://www.duuers.com/fi/blog/tarjouksen-tekeminen/seuraa-tarjouksen-etenemista"
            linkkiteksti={t('component.featureTestimonial.data.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.data.linkkiurl')}
            //invert={true}
            
          />
          <FeatureTestimonial
            sectionid="quality"
            title={t('component.featureTestimonial.quality.title')}
            description={t('component.featureTestimonial.quality.description')}
            //srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
            //src="/images/features/proposal-creation@2x.png"
            srcSet="/images/features/editor-fi.png 1x, /images/features/editor-fi@2x.png 2x, /images/features/editor-fi@3x.png 3x"
            src="/images/features/editor-fi.png"
            //linkkiteksti="Lue miksi laadukas tarjous on menestystekijä. →"
            //linkkiurl="https://www.duuers.com/fi/blog/yrittajyys/erotu-tarjoustyolla-kilpailijoista"
            linkkiteksti={t('component.featureTestimonial.quality.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.quality.linkkiurl')}
            invert={true}
          />
        {/*  <FeatureTestimonial
            sectionid="sales"
            title={t('component.featureTestimonial.sales.title')}
            description={t('component.featureTestimonial.sales.description')}
            srcSet="/images/features/better-sales@2x_.png 1x, /images/features/better-sales@2x_.png 2x"
            src="/images/features/better-sales@2x.png"
            
            //invert={true}
            />
          <FeatureTestimonial
            sectionid="customization"
            title={t('component.featureTestimonial.customization.title')}
            description={t('component.featureTestimonial.customization.description')}
            srcSet="/images/features/customization@2x_.png 1x, /images/features/customization@2x_.png 2x"
            src="/images/features/customization@2x_.png"
            invert={true}
            /> */}
      </div>
{
/*
// Clients detalis: Copy

      <section className={`content_34 padding_top60 padding_bottom60 video-section`}>
        <div className="container padding_bottom20  text-center">
          <h2 className="font42 light font_second dark_gray">{t('component.getStarted.title')}</h2>
          <div className="margin_auto top25 max_width770 font18 light text">{t('component.getStarted.description')}</div>
        </div>
        <VideoSection src="https://www.youtube.com/embed/r3ZWo4RpNMM?autoplay=1" poster="/images/hero_video-poster.png" bg="white"/>
      </section>
      <div id="features">
        <FeatureTestimonial
          title={t('component.featureTestimonial.faster.title')}
          description={t('component.featureTestimonial.faster.description')}
          srcSet="/images/features/proposal-creation@2x.png 1x, /images/features/proposal-creation@2x.png 2x"
          src="/images/features/proposal-creation@2x.png"
          video={{
            title: 'Heikin tarina',
            description: 'Heikki säästää kuukaudessa kolme päivää Duuersin avulla.',
            srcSet:"/images/references/heikki_500x500.png 1x, /images/references/heikki_500x500.png 2x",
            src:"/images/references/heikki_500x500.png",
            href:"https://www.youtube.com/embed/6HFdy3HRC_I?autoplay=1"
          }}/>
        <FeatureTestimonial
          title={t('component.featureTestimonial.sales.title')}
          description={t('component.featureTestimonial.sales.description')}
          srcSet="/images/features/better-sales@2x.png 1x, /images/features/better-sales@2x.png 2x"
          src="/images/features/better-sales@2x.png"
          video={{
            title: 'Janin tarina',
            description: 'Myynti ei voisi olla enää yksinkertaisempaa',
            srcSet:"/images/references/jani_500x500.png 1x, /images/references/jani_500x500.png 2x",
            src:"/images/references/jani_500x500.png",
            href:"https://www.youtube.com/embed/OZnvdvNnI0o?autoplay=1"
          }}
          invert={true}/>
        <FeatureTestimonial
          title={t('component.featureTestimonial.customization.title')}
          description={t('component.featureTestimonial.customization.description')}
          srcSet="/images/features/customization@2x.png 1x, /images/features/customization@2x.png 2x"
          src="/images/features/customization@2x.png"
          video={{
            title: 'Samin ja Villen tarina',
            description: 'Duuers antoi mahdollisuuden tuoda yritystämme paremmin esille tarjouksissa',
            srcSet:"/images/references/jax2_500x500.png 1x, /images/references/jax2_500x500.png 2x",
            src:"/images/references/jax2_500x500.png"  ,
            href:"https://www.youtube.com/embed/rwKsU3DE-sE?autoplay=1"
          }}       
          />
      </div>  
      */
} 

{
/*
//Partnerit ovat piilotettu
      <FeaturedIn logos={[{
        srcSet: '/images/featured/ukkopro.png 1x, /images/featured/ukkopro.png 2x',
        src: '/images/featured/ukkopro.png'
      },
      {
        srcSet: '/images/featured/holvi.png 1x, /images/featured/holvi.png 2x',
        src: '/images/featured/holvi.png'
      },
      {
        srcSet: '/images/featured/zeckit.png 1x, /images/featured/zeckit.png 2x',
        src: '/images/featured/zeckit.png'
      },{
        srcSet: '/images/featured/vastuu.png 1x, /images/featured/vastuu.png 2x',
        src: '/images/featured/vastuu.png'
      },{
        srcSet: '/images/featured/yrittajat.png 1x, /images/featured/yrittajat.png 2x',
        src: '/images/featured/yrittajat.png'
      }]}/>
      */
      }
      <Pricing currency="EUR"/>
      <section className={`feature_26 padding_top95 bg_light_blue padding_bottom100`}>
      	<div className="container nopadding">
      		<div className="row justify-content-between justify-content-xl-start">
      			<div className={`col-lg-6`}>
      				<img srcSet={null} src="/images/proposal_template_.png" className="width_full bg" alt="" />	
      			</div>
      			<div className={`col-lg-5 offset-1`}>
      				<div className="max_width600 inner">
      			{/*	<h1 className="font_second light font42">Miltä tarjous näyttää asiakkaallesi?</h1> */}
      					<h1 className="font_second light font42">{t('component.proposalmodel.title')}</h1>
      			{/*	<div className="top30 font18 light dark_blue hyphens text bottom30">Katso rekisteröitymättä miltä mallitarjouksemme näyttää asiakkaasi näkökulmasta!</div> */}
      			{/*	<div className="top30 font18 light dark_blue hyphens text bottom30">{t('component.proposalmodel.subtitle')}</div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Ammattimaisen näköinen tarjous, jolla erotut joukosta.</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Yrityksesi brändi ja toimiala esille, logon ja taustakuvan avulla.</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Asiakas voi kommentoida tarjousta ja voit vastata hänelle heti.</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>Tarjouksen hyväksyminen nappia painamalla.</span></div> */}
      					<div className="top30 font18 light dark_blue hyphens text bottom30">{t('component.proposalmodel.subtitle')}</div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.1')}</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.2')}</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.3')}</span></div>
      					<div className="bottom20 item flex" style={{flexWrap: 'nowrap'}}><i className="fa fa-check blue right50"></i><span>{t('component.proposalmodel.list.4')}</span></div>
			        {/*  <a rel="noopener noreferrer" target="_blank" href="https://app.duuers.com/preview-recipient-view/fi" className="btn blue size60 padding_sides35 radius6 top30 semibold width_full"><span className="white">Tutustu asiakkaan näkymää</span></a> */}
			          <a rel="noopener noreferrer" target="_blank" href={t('component.proposalmodel.button.url')} className="btn blue size60 padding_sides35 radius6 top30 semibold width_full"><span className="white">{t('component.proposalmodel.button.text')}</span></a>
      				</div>
      			</div>
      		</div>
      	</div>
      </section>
      <FeatureTestimonial
            sectionid="starttoday"
            title={t('component.featureTestimonial.starttoday.title')}
            description={t('component.featureTestimonial.starttoday.description')}
            srcSet="/images/features/customization@2x_.png 1x, /images/features/customization@2x_.png 2x"
            src="/images/features/customization@2x_.png"
            //linkkiteksti="Lue miten luot hyvän tarjouspohjan →"
            //linkkiurl="https://www.duuers.com/fi/blog/tarjouksen-tekeminen/10x-nopeammat_tarjoukset"
            linkkiteksti={t('component.featureTestimonial.starttoday.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.starttoday.linkkiurl')}
            invert={true}
            />
      <FeatureTestimonial
            sectionid="choose"
            title={t('component.featureTestimonial.choose.title')}
            description={t('component.featureTestimonial.choose.description')}
            phone={t('component.featureTestimonial.choose.phone')}
            //srcSet="/images/features/customization@2x_.png 1x, /images/features/customization@2x_.png 2x"
            //src="/images/features/customization@2x_.png"
            srcSet="/images/features/branding-fi_.png 1x, /images/features/branding-fi@2x_.png 2x, /images/features/branding-fi@3x_.png 3x"
            src="/images/features/branding-fi_.png"
            googlelomake="https://forms.gle/UCPL7gutvwXs8Jti6"
            osoite="Upseerinkatu 1, 02600 Espoo"
            //googlelomake="https://forms.gle/XH3M9iT5UfcRpLMU8"
            //linkkiteksti="asiakaspalvelu@duuers.com →"
            //linkkiurl="mailto:asiakaspalvelu@duuers.com"
            linkkiteksti={t('component.featureTestimonial.choose.linkkiteksti')}
            linkkiurl={t('component.featureTestimonial.choose.linkkiurl')}
            buttontext="Ota yhteyttä"
           // invert={true}
            />
      <section className={`content_34 padding_top60 padding_bottom60 video-section`}>
        <div className="container padding_bottom20  text-center">
          <h2 className="font42 light font_second dark_gray">{t('component.getStarted.title')}</h2>
          <div className="margin_auto top25 max_width770 font18 light text">{t('component.getStarted.description')}</div>
        </div>
        <VideoSection src="https://www.youtube.com/embed/r3ZWo4RpNMM?autoplay=1" poster="/images/hero_video-poster.png" bg="white"/>
      </section>
      <BlogSection posts={data.allMarkdownRemark.edges}  bg='light_blue'/>
      <section className={`content_34 padding_top60 padding_bottom60 phone-number`}>
        <div className='container nopadding'>  
        {/*  <h2 className='font_second light font40 dark_gray text-center'>Olemme täällä auttamassa</h2>
          <h2 className='font_second light font40 dark_gray text-center'>Olemme täällä auttamassa</h2>
          <p className='text-center font18 padding_top20'><a href='tel:+358108415654‬'><i className="fa fa-phone blue"></i>&nbsp;+358 10 841 5654</a></p> */}
          <h2 className='font_second light font40 dark_gray text-center'>{t('section.heretohelp.text')}</h2>
          <p className='text-center font18 padding_top20'><i className="fa fa-phone blue"></i>&nbsp;{t('section.heretohelp.phone')}</p>
        </div>
      </section>
      <Footer/>
    </WebLayout>
  )
}


export default withTranslation()(IndexPage);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}, 
      filter: {fields: {language: {eq: "fi"}}, frontmatter: { date: { ne: null } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            meta {
              name
              content
            }
            link {
              rel
              href
              hreflang
            }
            hero
          }
        }
      }
    }
  }
`